import './App.css';
import { Layout, Header, /*Navigation, Drawer,*/  Content } from 'react-mdl'
import Main from './components/main';
import { Link } from 'react-router-dom';
/*import { Component } from 'react';*/
import React, { useEffect } from 'react';


function App() {
  const title = 'Kyle Kriskovich'

  useEffect(() => {
    document.title = title;
  });

  return (
  
    <div className="demo-big-content">
    
    <Layout>
        <Header className="header-colour" title={<Link className="title-link" to='/'>{title}</Link>} style={{color: 'white'}} scroll>
            {/*<Navigation>
                {/* <Link to="/aboutme">About Me</Link>
                <Link to="/resume">Resume</Link>
                <Link to="/projects">GitHub</Link> */} {/* TODO in next version
            </Navigation>*/}
        </Header>
        {/* <Drawer title="Contents">
            <Navigation>
                <Link to="/aboutme">About Me</Link>
                <Link to="/resume">Resume</Link>
                <Link to="/projects">GitHub</Link>
            </Navigation>
        </Drawer> */}
        <Content>
          <div className="page-content"/>
          <Main/>
        </Content>
    </Layout>
</div>
  );
}

export default App;
